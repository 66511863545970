import React from "react";
import {graphql} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../../components/layout.de";
import Nav from "../../components/Nav.de"
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";

export const query = graphql`query ContactPageDeQuery {
  happyCustomer: file(relativePath: {eq: "woman-smiling-behind-counter.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 740, layout: CONSTRAINED)
    }
  }
  rentwareOffice: file(relativePath: {eq: "rentware-office-team.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 740, layout: CONSTRAINED)
    }
  }
  goeranSchwind: file(relativePath: {eq: "goeran-schwind-rentware-manager.png"}) {
    childImageSharp {
      gatsbyImageData(width: 250, layout: CONSTRAINED)
    }
  }
  techTeam: file(relativePath: {eq: "rentware-tech-team.png"}) {
    childImageSharp {
      gatsbyImageData(width: 250, layout: CONSTRAINED)
    }
  }
}
`

const ContactPageDe = ({data}) => {
    // useCalendlyInline();
    return (
      <Layout>
          <Seo
              seo={{
                  metaTitle: "Contact",
                  metaDescription: "It looks like you got to the contact",
              }}
          />
          <Nav/>
          <div className="grid mx-auto md:grid-cols-2 lg:max-w-screen-xl xl-max-w-screen-2xl lg:mt-12">
              <div className="max-w-2xl p-4 mb-4 md:p-6 md:my-6 lg:mt-12 lg:mb-20">
                  <h1>Fragen zu unseren Lösungen?</h1>
                  <p>Einer unserer Mitarbeiter bespricht mit Ihnen jede Frage, die Sie zu unserem Produkt haben.</p>
                  <div className="mt-2 font-bold">Produkt Team</div>
                  <a href="tel:+49309203854012" className="my-2 mr-2 btn btn--tertiary">+49 30 9203854012</a>
                  <a href="mailto:sales@rentware.com"
                     className="my-2 underline btn btn--tertiary">sales@rentware.com</a>
              </div>
              <div className="w-full max-w-2xl place-self-end">
                  <GatsbyImage
                    image={data.happyCustomer.childImageSharp.gatsbyImageData}
                    className="w-full"
                    alt="TODO" />
              </div>
          </div>
          <div
              className="grid gap-6 mx-auto md:items-center sm:grid-cols-2 lg:grid-cols-3 lg:gap-16 lg:max-w-screen-xl xl-max-w-screen-2xl">
              <ContactPerson
                  headline='Geschäftsmöglichkeiten'
                  text='Sie können sich auch jederzeit an uns wenden, um Ihre Geschäftsmöglichkeit mit Rentware zu besprechen.'
                  contactImage={data.goeranSchwind.childImageSharp.gatsbyImageData}
                  contactImageAltAttribute='Business portrait of Göran Schwind'
                  contactName='Göran Schwind'
                  contactPosition='Geschäftsführer'
                  contactBtnLinkUrl='mailto:sales@rentware.com'
                  contactBtnLinkText='sales@rentware.com'
              />
              <div
                  className="order-3 w-full mx-auto lg:order-2 sm:col-span-2 sm:max-w-md lg:max-w-none lg:col-span-1">
                  <GatsbyImage
                    image={data.rentwareOffice.childImageSharp.gatsbyImageData}
                    className="w-full"
                    alt="TODO" />
              </div>
              <ContactPerson
                  className='order-2 lg:order-3'
                  headline='Technische Unterstützung'
                  text='Möchten Sie mit unserer API arbeiten oder haben Sie technische Fragen? Lassen Sie es uns wissen!'
                  contactImage={data.techTeam.childImageSharp.gatsbyImageData}
                  contactImageAltAttribute='Business portrait of Göran Schwind'
                  contactName='Tech team'
                  contactPosition='API und Technologien'
                  contactBtnLinkUrl='mailto:techteam@rentware.com'
                  contactBtnLinkText='techteam@rentware.com'
              />
          </div>
      </Layout>
    );
}

export default ContactPageDe;
